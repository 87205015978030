/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import { MdArrowForward } from "react-icons/md";
import Image from "gatsby-image";

import { listStyles } from "../../../styles/shared.styles";

import {
	containerStyles,
	CTAContainerStyles,
	CTATextStyles,
} from "./Index.styles";

const PostCard = (props) => {
	var { frontmatter } = props;
	return (
		<div sx={containerStyles}>
			<PostCard.coverImage frontmatter={frontmatter} />

			<ul sx={listStyles}>
				<li>
					<Text
						variant="body"
						as="h3"
						sx={{
							fontWeight: "semibold",
						}}
					>
						{frontmatter.title}
					</Text>

					<PostCard.Author frontmatter={frontmatter} />

					<Text variant="caption" as="p" sx={{ color: "grays.60" }}>
						{frontmatter.description}
					</Text>
				</li>
				<li sx={CTAContainerStyles}>
					<p sx={CTATextStyles}>Check it out</p>
					<MdArrowForward />
				</li>
			</ul>
		</div>
	);
};

export default PostCard;

PostCard.coverImage = ({ frontmatter }) => {
	return frontmatter.coverImage ? (
		<div
			sx={{
				width: "100%",
				marginRight: [0, 4, 4],
				marginTop: [0, 2, 2],
				marginBottom: [4, 2, 2],
			}}
		>
			<Image fluid={frontmatter.coverImage.childImageSharp.fluid} />
		</div>
	) : null;
};

PostCard.Author = ({ frontmatter }) => {
	return frontmatter.author ? (
		<p
			sx={{
				fontSize: 1,
				marginTop: 1,
				marginBottom: 3,
			}}
		>
			by {frontmatter.author}
		</p>
	) : null;
};
