import { listStyles } from "../../../styles/shared.styles";

export const containerStyles = {
	...listStyles,
	display: "flex",
	flexFlow: ["row wrap", "row nowrap", "row nowrap"],
	alignItems: "center",
	marginBottom: 3,
	padding: 3,
	boxShadow: "10",
	border: "light",
	borderRadius: 4,
	backgroundColor: "tile",
	transition: ".25s ease-in-out",
	":hover": {
		boxShadow: "20",
		transition: ".25s ease-in-out",
		h3: {
			color: "primary",
			transition: ".3s ease-in-out",
		},
	},
};

export const CTAContainerStyles = {
	marginTop: 2,
	display: "flex",
	flexFlow: "row wrap",
	color: "primary",
};

export const CTATextStyles = {
	fontSize: 1,
	fontWeight: "600",
	marginRight: 2,
};
