/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import { Link } from "gatsby";

import PostCard from "./postcard/Index";

const Index = (props) => {
	var { edges } = props.data;
	return (
		<div sx={{ marginTop: 4 }}>
			{edges.map(({ node }) => {
				return PostCardType(node);
			})}
		</div>
	);
};

export default Index;

// this function decides which Link to use ( Gatsby or a href) depending on whether external Slug is defined.
function PostCardType(node) {
	var { frontmatter, fields, id } = node;
	if (frontmatter.externalSlug) {
		return (
			<a
				href={frontmatter.externalSlug}
				target="_blank"
				rel="noreferrer"
				sx={{ color: "text" }}
				key={id}
				aria-label={frontmatter.title}
			>
				<PostCard frontmatter={frontmatter} />
			</a>
		);
	}
	return (
		<Link
			sx={{ color: "text" }}
			to={fields.slug}
			key={id}
			aria-label={frontmatter.title}
		>
			<PostCard frontmatter={frontmatter} />
		</Link>
	);
}
