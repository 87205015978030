/** @jsx jsx */
import { jsx, Text, Link, useColorMode } from "theme-ui";
import React from "react";
import { gridStyles, bodyColumn } from "../styles/shared.styles";
import { graphql } from "gatsby";
import PostList from "../components/post-list/Index";
import SEO from "../components/seo";

var { useEffect } = React;

const Notes = (props) => {
	var { allMdx } = props.data;
	return (
		<>
			<SEO title="Notes" />
			<main sx={{ ...gridStyles, marginTop: 5 }} role="main">
				<div sx={bodyColumn}>
					<Text variant="display" sx={{ marginBottom: 3 }} as="h1">
						Notes
					</Text>
					<Text variant="body" sx={{ color: "grays.60" }}>
						An on-going experimentation of unpolished note taking,
						that includes the evolution of my working and thinking
						process.
					</Text>
					<PostList data={allMdx} />
				</div>
			</main>
		</>
	);
};

export default Notes;

// gets access to all of my blog posts
export const query = graphql`
	query {
		allMdx(
			filter: { fields: { slug: { regex: "/notes/" } } }
			sort: { fields: frontmatter___date, order: DESC }
		) {
			edges {
				node {
					id
					frontmatter {
						title
						description
					}
					fileAbsolutePath
					fields {
						slug
					}
					body
				}
			}
		}
	}
`;
